<script lang="ts">
export default {
  name: 'AccountLayout',
};
</script>

<script setup lang="ts">
const route = useRoute();
const to = route.query.to as string;
const params = { to: to };

useAuthGuardRedirection(params);

// Navigation for Account page
const { loadNavigationElements } = useNavigation();
const { logout } = useUser();
const router = useRouter();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { data } = await useAsyncData('mainNavigation', () => {
  return loadNavigationElements({ depth: 2 });
});
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: 'footer-navigation',
});
const { data: footerData } = await useAsyncData('mainFooterNavigation', () => {
  return loadFooterNavigationElements({ depth: 2 });
});

async function invokeLogout() {
  logout();
  router.push('/');
}

provide('swNavigation-footer-navigation', footerData);
</script>

<template>
  <div class="flex min-h-screen min-w-full flex-col">
    <LayoutHeader class="w-full" />
    <LayoutNotifications />
    <main class="max-w-superlarge mx-auto w-full grow">
      <div
        class="account-page mx-auto min-h-full max-w-screen-xl pt-20 lg:pt-40"
      >
        <div class="m-10">
          <div class="account-inner">
            <div class="md:grid md:grid-cols-6 md:gap-6">
              <aside class="block md:col-span-1" aria-label="Sidebar">
                <div
                  class="overflow-y-auto rounded text-base font-normal text-gray-500 md:bg-gray-50 md:px-3 md:py-4"
                >
                  <h1
                    class="mb-2 self-center whitespace-nowrap px-0 py-2 text-xl font-semibold md:px-2"
                  >
                    {{ $t('account.menu.myAccount') }}
                  </h1>
                  <ul class="list-none space-y-2 pl-0">
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/profile`)"
                        class="hover:text-brand-primary is-active flex items-center px-0 py-2 hover:bg-gray-100 md:px-2"
                      >
                        <div i-carbon-user text-xl inline-block />
                        <span class="ml-3">{{
                          $t('account.yourProfile')
                        }}</span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/address`)"
                        class="hover:text-brand-primary flex items-center px-0 py-2 hover:bg-gray-100 md:px-2"
                      >
                        <div i-carbon-home text-xl inline-block />
                        <span class="ml-3">{{
                          $t('account.yourAddress')
                        }}</span>
                      </NuxtLink>
                    </li>
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/payment`)"
                        class="hover:text-brand-primary flex items-center px-0 py-2 hover:bg-gray-100 md:px-2"
                      >
                        <div i-carbon-wallet text-xl inline-block />
                        <span class="ml-3">{{
                          $t('account.myPaymentsHeader')
                        }}</span>
                      </NuxtLink>
                    </li>
                  </ul>
                  <ul
                    class="mt-4 list-none space-y-2 border-t border-gray-200 pl-0 pt-4"
                  >
                    <li>
                      <NuxtLink
                        :to="formatLink(`/account/order`)"
                        class="hover:text-brand-primary flex items-center px-0 py-2 hover:bg-gray-100 md:px-2"
                      >
                        <div i-carbon-order-details text-xl inline-block />
                        <span class="ml-3">{{
                          $t('account.orderHistoryHeader')
                        }}</span>
                      </NuxtLink>
                    </li>
                    <li>
                      <button
                        class="hover:text-brand-primary flex w-full items-center bg-transparent px-0 py-2 hover:bg-gray-100 md:px-2"
                        @click="invokeLogout()"
                      >
                        <div i-carbon-logout text-xl inline-block />
                        <span class="ml-3 text-gray-700">{{
                          $t('account.logout')
                        }}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </aside>
              <main class="md:col-span-5">
                <slot />
              </main>
            </div>
          </div>
        </div>
      </div>
    </main>
    <LayoutFooter class="max-w-superlarge mx-auto w-full" />
  </div>
</template>
